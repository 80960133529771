import course from "./course";
import courses from "./course/class/courses";
import courseGroup from "./course/class/courseGroup";
import alert from "./appNotification/alert";
import classes from "./course/class";
import exam from "./course/exam";
import board from "./course/class/board";
import programs from "./programs";
import cart from "./course/cart";
import checkout from "./course/cart/checkout";
import detail from "./course/detail";
import auth from "./account/auth";
import headerType from "./landing/headerType";
import signUp from "./signUp";
import specialCourses from "./course/specialCourses";
import popularExams from "./course/popularExams";
import cancelPendingReq from "./cancelPendingReq";
import demo from "./demo";
import programCategories from "./course/programCategories";
import blogs from "./blogs";
export default {
  programs,
  course,
  alert,
  classes,
  exam,
  board,
  cart,
  courseGroup,
  courses,
  detail,
  checkout,
  auth,
  headerType,
  signUp,
  cancelPendingReq,
  specialCourses,
  popularExams,
  blogs,
  demo,
  programCategories,
};
