import { servicesFactory } from "../../../services/serviceFactory.js";
export default {
  namespaced: true,
  state: () => ({
    programCategories: [],
  }),
  getters: {
    programCategories(state) {
      return state.programCategories;
    },
    getCategories(state) {
      const uniqueAges = [...new Set(state.programCategories.map((obj) => obj.ProgramCategory.ProgramCategoryId))].map((id) => {
        return state.programCategories.find((obj) => obj.ProgramCategory.ProgramCategoryId === id);
      });
      return uniqueAges;
    },
    featuredProgramCategories(state) {
      const uniqueAges = [...new Set(state.programCategories.map((obj) => obj.ProgramCategory.ProgramCategoryId))].map((id) => {
        return state.programCategories.find((obj) => {
          return obj.ProgramCategory.ProgramCategoryId === id && obj.HasFeaturedSoS == true;
        });
      });
      return [
        ...uniqueAges.filter((cat) => {
          return cat && cat.HasFeaturedSoS == true;
        }),
      ];
    },
    filteredRecords: (state) => (categoryId, programId, boardId, groupId) => {
      if (state.programCategories.length) {
        let array = [];
        if (programId && boardId && groupId) {
          array = state.programCategories.filter((rec) => {
            return rec.AcademicProgram && rec.AcademicProgram.AcademicProgramId == programId && rec.Board && rec.Board.BoardId == boardId && rec.Group && rec.Group.CourseGroupId == groupId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else if (programId && boardId) {
          array = state.programCategories.filter((rec) => {
            return rec.AcademicProgram && rec.AcademicProgram.AcademicProgramId == programId && rec.Board && rec.Board.BoardId == boardId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else if (programId && groupId) {
          array = state.programCategories.filter((rec) => {
            return rec.AcademicProgram && rec.AcademicProgram.AcademicProgramId == programId && rec.Group && rec.Group.CourseGroupId == groupId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else if (boardId && groupId) {
          array = state.programCategories.filter((rec) => {
            return rec.Board && rec.Board.BoardId == boardId && rec.Group && rec.Group.CourseGroupId == groupId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else if (programId) {
          array = state.programCategories.filter((rec) => {
            return rec.AcademicProgram && rec.AcademicProgram.AcademicProgramId == programId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else if (boardId) {
          array = state.programCategories.filter((rec) => {
            return rec.Board && rec.Board.BoardId == boardId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else if (groupId) {
          array = state.programCategories.filter((rec) => {
            return rec.Group && rec.Group.CourseGroupId == groupId && rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        } else {
          array = state.programCategories.filter((rec) => {
            return rec.ProgramCategory && rec.ProgramCategory.ProgramCategoryId == categoryId;
          });
        }
        return array;
      }
    },
  },
  mutations: {
    setProgramCategories(state, model) {
      state.programCategories = model;
    },
    resetState(state) {
      state.programCategories = [];
    },
  },
  actions: {
    resetProgramCategories({ state, commit }) {
      return commit("resetState");
    },
    getProgramCategories({ commit, state, root, dispatch, getters }, param) {
      let programCategories = getters.programCategories;
      if (programCategories.length == 0) {
        return servicesFactory
          .createService(servicesFactory.serviceType.enums.services.lmsService)
          .courseService.getProgramCategoryCourses(param)
          .then((response) => {
            commit("setProgramCategories", response.data.ProgramCategories);
          })
          .catch((ex) => {
            if (process.env.VUE_ENV === "client") {
              throw new Error("Server Error");
            }
          });
      } else {
        return programCategories;
      }
    },
  },
};
