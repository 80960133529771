// store/modules/blogStore.js
import axios from "axios";

export default {
  namespaced: true,
  state: {
    blogs: [],
  },
  mutations: {
    setBlogs(state, blogs) {
      state.blogs = blogs;
    },
  },
  getters: {
    getBlogs: (state) => state.blogs,
  },
  actions: {
    async fetchBlogs({ state, commit }) {
      if (state.blogs.length === 0) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_BLOG_URL}wp-json/wp/v2/posts`);
          const blogs = response.data.map((post) => ({
            id: post.id,
            title: post.title,
            excerpt: post.excerpt,
            date: post.published_date || "N/A",
            content: post.content,
            featured_image_src: post.featured_image_src || "/assets/images/default-placeholder.png",
          }));
          commit("setBlogs", blogs);
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      }
    },
  },
};
