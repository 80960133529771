<template>
  <section id="sectionFooter" class="footer-sections">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-4 footer-info">
          <span class="footer-head d-block">Contact us</span>
          <ul class="contact-info">
            <li>
              <a href="mailto:info@kipsvirtual.com">info@kipsvirtual.com</a>
            </li>
            <li><a href="tel:042111547775">(042) 111 547 775</a></li>
            <li>
              <a href="https://goo.gl/maps/M24HR1tHXdEApySZ8" target="_blank">
                5/6, D-1 Khayaban-e-Firdousi, Johar Town Lahore, Pakistan</a>
            </li>
          </ul>
          <div class="footer-icons">
            <a href="http://www.facebook.com/kipsvirtual" target="_blank">
              <img src="/assets/images/facebook.svg" alt="" width="19" height="19">
            </a>
            <a href="http://www.instagram.com/kipsvirtual" target="_blank">
              <img src="/assets/images/instagram.svg" alt="" width="19" height="19">
            </a>
            <a href="https://www.youtube.com/@KIPSVirtual" target="_blank">
              <img src="/assets/images/youtube.svg" alt="" width="19" height="19">

            </a>
            <a href="https://www.tiktok.com/@kipsvirtual?_t=8nlOXUVGXdh&_r=1" target="_blank">
              <img src="/assets/images/tiktok.svg" alt="" width="19" height="19">
            </a>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 footer-info">
          <span class="footer-head d-block">Useful links</span>
          <ul class="footer-links">
            <li>
              <router-link class="footer-links" to="/category">Enroll</router-link>
            </li>
            <li>
              <router-link class="footer-links" to="/popularPrograms">Featured Programs</router-link>
            </li>
            <li>
              <router-link class="footer-links" to="/faq">FAQs</router-link>
            </li>
            <li>
              <router-link class="footer-links" to="/about">About Us</router-link>
            </li>
            <li>
              <router-link class="footer-links" to="/blogs">Blogs</router-link>
            </li>
            <!-- <li>
              <router-link class="footer-links" to="/howitworks">How it works</router-link>
            </li> -->
          </ul>
        </div>

        <div class="col-md-6 col-lg-4 footer-info">
          <span class="footer-head d-block">KIPS Projects</span>
          <ul class="footer-links">
            <li>
              <a class="footer-links" target="_blank" href="https://kips.edu.pk/">KIPS Education System</a>
            </li>
            <li>
              <a class="footer-links" target="_blank" href="https://kipspublications.com/">KIPS Publicatons</a>
            </li>
            <li>
              <a class="footer-links" target="_blank" href="https://kipsschool.com/">KIPS School</a>
            </li>
            <li>
              <a class="footer-links" target="_blank" href="https://7csschool.com/">7Cs School</a>
            </li>
            <li>
              <a class="footer-links" target="_blank" href="https://kipscolleges.com/">KIPS College</a>
            </li>
            <li>
              <a class="footer-links" href="#keys-academy">KEYS Academy</a>
            </li>
            <li>
              <a class="footer-links" target="_blank" href="https://kipsprep.com/">KIPS Preprations</a>
            </li>
            <li>
              <a class="footer-links" target="_blank" href="https://qb-franchising.com/">QBFN</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 footer-bottom-left">
          <ul>
            <li>
              <router-link class="footer-links" to="/privacy">Privacy Policy</router-link>
            </li>
            <li>
              <router-link class="footer-links" to="/refund">Refund Policy</router-link>
            </li>

            <li>
              <router-link class="footer-links" to="/terms&conditions">Terms & Conditions</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="footer-bottom-right">
            <li class="payment-option">
              <img src="/assets/images/visa-card.jpg" width="52" height="27" />
            </li>
            <li class="payment-option">
              <img src="/assets/images/master-card.jpg" width="52" height="27" />
            </li>
            <li class="payment-option">
              <img src="/assets/images/jazzcash.jpg" width="52" height="27" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { blogs as blogsStoreKeys } from "../../store/keys";
export default {
  computed: {
    ...mapGetters(blogsStoreKeys.namespace, blogsStoreKeys.getters),
  },
};
</script>
