<template>
  <div>
    <section class="banner-section">
      <!-- <img class="spiral-img" src="/assets/images/homepage/header-spiral.svg" /> -->
      <div class="container banner-cntr">
        <div class="row">
          <div class="col-md-12 mx-auto banner-text text-center">
            <h1 class="heading">
              <small class="sub-heading d-block pl-2">KIPS Virtual guarantees unbounded, High-Quality Education</small>
              Virtual Learning that
              <br />
              <span>transforms actuality</span>
            </h1>
            <!-- <router-link to="/category" class="btn primary-btn">Start Your Journey Now</router-link> -->
            <div class="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-center">
              <button @click="openBookDemoPopup()" class="btn-advice green-btn text-white">Take a Virtual Tour</button>
              <button @click="openExpertAdvicePopup()" class="btn-advice yellow-btn text-white mt-2 mt-lg-0 ml-lg-2">Ask
                an Expert</button>
            </div>
          </div>
        </div>
        <div class="banner-img-container">
          <img src="/assets/images/banner/banner-avatar.svg" />
        </div>
      </div>
    </section>

    <section class="programs-offer" id="sectionPrograms">
      <div class="container">
        <div class="row">
          <div class="col-md-12 programs-head">
            <h2>Our Programs</h2>
            <p>We offer comprehensive online education from secondary school to the preparation of entrance into
              professional institutes</p>
          </div>
        </div>
        <!-- <categories /> -->
        <programCategories />
        <!-- <div class="mt-md-5 text-center">
          <span class="d-inline-block"><button class="btn-advice green-btn text-white">
              Free Trail
            </button></span>
        </div> -->
      </div>
    </section>
    <section class="popular-exams">
      <div class="container">
        <div class="row">
          <div class="popular-exams-head text-left col-md-12">
            <h2>Featured Programs</h2>
            <p>Take a look at our programs being taken by a multitude of students</p>
          </div>
        </div>
        <popular-exam />
        <div class="btn-container mt-0 mt-lg-4">
          <router-link to="/popularPrograms" class="btn secondary-btn mt-4 mt-lg-3">Explore More</router-link>
          <button @click="openExpertAdvicePopup()" class="btn-advice yellow-btn text-white mt-4 ml-2 mt-lg-3">Ask an
            Expert</button>
        </div>
      </div>
      <div class="circle-pattern-2">
        <img src="/assets/images/banner/circle-pattern-2.svg " alt="" />
      </div>
    </section>
    <section class="features-section">
      <div class="container">
        <div class="row">
          <div class="popular-exams-head text-center col-md-12">
            <h2>Features</h2>
            <p>With KIPS Virtual, you get everything you need to excel in your career-defining years of education</p>
          </div>
        </div>

        <div class="row column-order-reverse">
          <div class="col-md-5 col-lg-4">
            <div class="accordion" role="tablist">
              <div @click="findId(1)">
                <div block v-b-toggle.accordion-1 class="small-heading-white">
                  <span>01</span>
                  e-Lectures
                </div>
                <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Benefit from high-quality recorded lectures delivered by our seasoned subject
                    experts</p>
                </b-collapse>
              </div>

              <div @click="findId(2)">
                <div block v-b-toggle.accordion-2 class="small-heading-white">
                  <span>02</span>
                  Readings
                </div>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Get erudition of the highest class through our meticulously prepared notes for
                    all subjects</p>
                </b-collapse>
              </div>
              <div @click="findId(3)">
                <div block v-b-toggle.accordion-3 class="small-heading-white">
                  <span>03</span>
                  Quizzes & Assignments
                </div>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Get yourself evaluated by the best in the business through adaptive tests and
                    methodically designed assignments</p>
                </b-collapse>
              </div>
              <div @click="findId(4)">
                <div block v-b-toggle.accordion-4 class="small-heading-white">
                  <span>04</span>
                  Live Classes
                </div>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Get your concepts cleared by engaging with our highly experienced faculty in
                    live interactive sessions</p>
                </b-collapse>
              </div>

              <div @click="findId(5)">
                <div block v-b-toggle.accordion-5 class="small-heading-white">
                  <span>05</span>
                  Teacher Support
                </div>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Chat with subject experts to find instant answers to your queries and get your
                    doubts resolved</p>
                </b-collapse>
              </div>
              <div @click="findId(6)">
                <div block v-b-toggle.accordion-6 class="small-heading-white">
                  <span>06</span>
                  Notes & Bookmarks
                </div>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Jot down notes and bookmark important videos and readings to access them
                    whenever you require to</p>
                </b-collapse>
              </div>
              <div @click="findId(7)">
                <div block v-b-toggle.accordion-7 class="small-heading-white">
                  <span>07</span>
                  Progress Reports
                </div>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">Track your progress through instant results and detailed performance charts
                  </p>
                </b-collapse>
              </div>

              <div @click="findId(8)">
                <div block v-b-toggle.accordion-8 class="small-heading-white">
                  <span>08</span>
                  Leaderboard
                </div>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">View where you stand in comparison with other students and the top position
                    holders in your program</p>
                </b-collapse>
              </div>
              <div @click="findId(9)">
                <div block v-b-toggle.accordion-9 class="small-heading-white">
                  <span>09</span>
                  AI Quizzes
                </div>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                  <p class="content-para">
                    Optimize your learning by getting assistance from AI to automatically generate quizzes targeting the
                    areas you need to improve in

                  </p>
                </b-collapse>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-8">
            <div class="accordian-img-container">
              <img class="accordian-img" :src="url" width="704" height="auto" />
              <div class="circle-pattern">
                <img src="/assets/images/banner/circle-pattern.svg " alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="faqs-section">
      <div class="circle-pattern-4">
        <img src="/assets/images/banner/circle-pattern-4.svg " alt="" />
      </div>
      <div class="container">
        <div class="row">
          <div class="faqs-head text-white text-center col-md-12">
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>
        <div class="row faqs-row">
          <div class="col-md-5">
            <div class="faq-left">
              <svg class="question-mark" xmlns="http://www.w3.org/2000/svg" width="58.499" height="68.009"
                viewBox="0 0 58.499 68.009">
                <g id="Group_12833" data-name="Group 12833" transform="translate(-116 -3716.767)">
                  <g id="Speech_Bubble" data-name="Speech Bubble" transform="translate(116 3716.767)">
                    <g id="Group_11907" data-name="Group 11907">
                      <g id="Group_11903" data-name="Group 11903">
                        <g id="Group_11900" data-name="Group 11900" transform="translate(0.233 0.199)">
                          <path id="Path_12606" data-name="Path 12606"
                            d="M124.191,63.91H66.122v56.272H86.047l10.038,11.181,8.287-11.181h19.819Z"
                            transform="translate(-66.122 -63.91)" fill="#fff" />
                        </g>
                        <g id="Group_11902" data-name="Group 11902">
                          <g id="Group_11901" data-name="Group 11901">
                            <path id="Path_12607" data-name="Path 12607"
                              d="M124.192,63.911s.015.334.022,1c.005.688.013,1.681.022,2.973.012,2.634.029,6.472.051,11.364.024,9.853.059,23.938.1,40.931v.2h-.2l-19.819.02.173-.088c-2.627,3.548-5.418,7.32-8.28,11.186l-.164.223-.184-.205L85.877,120.338l.171.077-14.609,0H65.891v-.232c.012-20.628.023-39.867.033-56.272v-.2h.2l42.185.1,11.759.052,3.083.023c.695.007,1.041.022,1.041.022s-.377.014-1.085.022l-3.121.023-11.8.051-42.058.1.2-.2c.009,16.405.021,35.644.033,56.272l-.233-.233h5.315l14.609,0h.1l.068.077,10.034,11.185-.348.016,8.294-11.176.065-.088h.109l19.819.02-.2.2c.042-16.94.077-30.982.1-40.806.021-4.911.038-8.765.05-11.409q.015-1.964.023-3.012C124.178,64.274,124.192,63.911,124.192,63.911Z"
                              transform="translate(-65.891 -63.712)" fill="#263238" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <path id="Path_12671" data-name="Path 12671"
                    d="M68.962,274.16v-1.287c0-6.636,8.4-6.921,8.4-11.65,0-2.052-1.669-3.387-4.173-3.387-2.72,0-4.438,1.482-4.438,3.582v.522c0,.529-.285.765-.814.765l-5.105-.188c-.48,0-.814-.243-.814-.577v-.522c0-5.773,4.584-9.738,11.317-9.738,6.587,0,10.83,3.721,10.83,9.306,0,7.922-8.541,7.971-8.541,12.318v.856a.73.73,0,0,1-.814.814h-5.05a.73.73,0,0,1-.8-.814Zm-.431,7.686a4.09,4.09,0,1,1,4.062,3.818,3.951,3.951,0,0,1-4.076-3.818Z"
                    transform="translate(71.875 3477.493)" fill="#6163b0" />
                </g>
              </svg>

              <h3 class="faqs-heading pt-3">Have Any Questions?</h3>
              <!-- For Mobile Only -->
              <h3 class="faqs-heading-mobile pt-3">
                <svg class="question-mark-mobile" xmlns="http://www.w3.org/2000/svg" width="58.499" height="68.009"
                  viewBox="0 0 58.499 68.009">
                  <g id="Group_12833" data-name="Group 12833" transform="translate(-116 -3716.767)">
                    <g id="Speech_Bubble" data-name="Speech Bubble" transform="translate(116 3716.767)">
                      <g id="Group_11907" data-name="Group 11907">
                        <g id="Group_11903" data-name="Group 11903">
                          <g id="Group_11900" data-name="Group 11900" transform="translate(0.233 0.199)">
                            <path id="Path_12606" data-name="Path 12606"
                              d="M124.191,63.91H66.122v56.272H86.047l10.038,11.181,8.287-11.181h19.819Z"
                              transform="translate(-66.122 -63.91)" fill="#fff" />
                          </g>
                          <g id="Group_11902" data-name="Group 11902">
                            <g id="Group_11901" data-name="Group 11901">
                              <path id="Path_12607" data-name="Path 12607"
                                d="M124.192,63.911s.015.334.022,1c.005.688.013,1.681.022,2.973.012,2.634.029,6.472.051,11.364.024,9.853.059,23.938.1,40.931v.2h-.2l-19.819.02.173-.088c-2.627,3.548-5.418,7.32-8.28,11.186l-.164.223-.184-.205L85.877,120.338l.171.077-14.609,0H65.891v-.232c.012-20.628.023-39.867.033-56.272v-.2h.2l42.185.1,11.759.052,3.083.023c.695.007,1.041.022,1.041.022s-.377.014-1.085.022l-3.121.023-11.8.051-42.058.1.2-.2c.009,16.405.021,35.644.033,56.272l-.233-.233h5.315l14.609,0h.1l.068.077,10.034,11.185-.348.016,8.294-11.176.065-.088h.109l19.819.02-.2.2c.042-16.94.077-30.982.1-40.806.021-4.911.038-8.765.05-11.409q.015-1.964.023-3.012C124.178,64.274,124.192,63.911,124.192,63.911Z"
                                transform="translate(-65.891 -63.712)" fill="#263238" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <path id="Path_12671" data-name="Path 12671"
                      d="M68.962,274.16v-1.287c0-6.636,8.4-6.921,8.4-11.65,0-2.052-1.669-3.387-4.173-3.387-2.72,0-4.438,1.482-4.438,3.582v.522c0,.529-.285.765-.814.765l-5.105-.188c-.48,0-.814-.243-.814-.577v-.522c0-5.773,4.584-9.738,11.317-9.738,6.587,0,10.83,3.721,10.83,9.306,0,7.922-8.541,7.971-8.541,12.318v.856a.73.73,0,0,1-.814.814h-5.05a.73.73,0,0,1-.8-.814Zm-.431,7.686a4.09,4.09,0,1,1,4.062,3.818,3.951,3.951,0,0,1-4.076-3.818Z"
                      transform="translate(71.875 3477.493)" fill="#6163b0" />
                  </g>
                </svg>
                Have Any Questions?
              </h3>
              <p class="py-3 description">Get your confusions removed, your queries addressed, and your information
                about KIPS Virtual streamlined.</p>
              <router-link to="/faq" class="faq-button btn primary-btn">Explore More</router-link>
            </div>
          </div>
          <div class="col-md-7">
            <div class="faq-accordion" role="tablist">
              <b-card no-body class="mb-1" style="position: relative">
                <b-card-header header-tag="header" role="tab">
                  <div block v-b-toggle.accordion-1 variant="info">
                    <div class="d-flex justify-content-between">
                      <div>How long does my student account remain valid?</div>
                      <div class="icon"></div>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>Once you have signed up, your account remains valid for as long as you wish to keep it.
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1" style="position: relative">
                <b-card-header header-tag="header" role="tab">
                  <div block v-b-toggle.accordion-2 variant="info">
                    <div class="d-flex justify-content-between">
                      <div>How do I attempt tests, watch videos, and view readings?</div>
                      <div class="icon">
                        <!-- <div class="plus">+</div>
                        <div class="minus">-</div> -->
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>When you click on a subject's tab on "My Programs" page, you will find readings,
                      videos, tests,and assignments. Alternatively, you can search for your desired videos and
                      readings.</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1" style="position: relative">
                <b-card-header header-tag="header" role="tab">
                  <div block v-b-toggle.accordion-3 variant="info">
                    <div class="d-flex justify-content-between">
                      <div>How do I contact KIPS Virtual support team?</div>
                      <div class="icon">
                        <!-- <div class="plus">+</div>
                        <div class="minus">-</div> -->
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>Send an email to support@kipsvirtual.com. Your queries will be catered within 48 hours.
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1" style="position: relative">
                <b-card-header header-tag="header" role="tab">
                  <div block v-b-toggle.accordion-4 variant="info">
                    <div class="d-flex justify-content-between">
                      <div>What do I do if I get registered to the wrong program? How do I change the registered
                        program?</div>
                      <div class="icon">
                        <!-- <div class="plus">+</div>
                        <div class="minus">-</div> -->
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>You need to provide all the details to the campus manager, after whose approval, your
                      program will be changed accordingly. </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="faq-button2">
            <router-link to="/faq" class="btn primary-btn text-white">Explore More</router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="success-stories-section">
      <div class="container">
        <div class="row">
          <div class="success-stories text-center col-md-12">
            <h2>Success Stories</h2>
            <p>Keeping up with the tradition of achieving great heights, KIPSians who have benefitted from our online
              learning platform have been extremely successful</p>
          </div>
        </div>
        <SsrCarousel loop :slides-per-page="3" :responsive="[
          { maxWidth: 1024, slidesPerPage: 2 },
          { maxWidth: 600, slidesPerPage: 1 },
        ]" show-dots>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">I will recommend everyone aspiring to succeed in ECAT or any entry test to join
                KIPS and benefit from their wonderful teaching methodology and great online resources and support.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/1HanzalaSajjad.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Malik Hanzla Sajjad</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">ECAT</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">I was blessed to be a part of KIPS which instilled confidence in me to aim for
                nothing less than the best through its supportive faculty, great preparation resources, and unmatched
                online learning tools that allowed me to study whenever and wherever I wished to do so.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/2GhaziaAssar.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Ghazia Assar</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">MDCAT</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">KIPS equipped me with all the knowledge and skill I needed to perform exceptionally
                well in my exam. Its online learning management system with recorded lectures, reading material, and
                quizzes helped me greatly in my preparation.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/3AadelAsad.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Aadel Asad</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">ECAT-FAST</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">I owe my success to my teachers and learning resources at KIPS, especially the
                online facilities they provided through which I could prepare for my exam from the comfort of my home.
              </p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/4AyeshaSajjad.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Ayesha Sajjad</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">NUMS</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">My teachers at KIPS were very cooperative and the online LMS made preparation very
                easy with all resources available at all times.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/5QayyumAhmad.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Qayyum Ahmad</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">NUST</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">KIPS gave me clarity of goals and all I needed to achieve those goals by addressing
                my difficulties in preparing for my exam. Their comprehensive online learning platform proved extremely
                beneficial in clarifying my doubts through online teacher support.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/6AyeshaAsif.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Ayesha Asif</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">1073 Marks</span>
                  <span class="d-block">HSSC Pre-Medical</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">KIPS taught me how to manage my time and the pressure of undertaking a test that
                was going to be decisive in my career. The online LMS made it very easy for me to learn and put my
                learning into practice at my convenience.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/7MTalhaKhan.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">M Talha Khan</strong>
                  <span class="d-block">1st Position</span>
                  <span class="d-block">1076 Marks</span>
                  <span class="d-block">HSSC Pre-Medical</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">When I first heard about this online learning system, I had doubts about its
                efficacy, but when I used it, I was awed by its comprehensiveness, user-friendliness and adaptivity.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/8MAliRehan.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">M. Ali Rehan</strong>
                  <span class="d-block">Overall 3rd</span>
                  <span class="d-block">1091 Marks</span>
                  <span class="d-block">HSSC Pre-Medical</span>
                </div>
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="success-box">
              <i class="fas fa-quote-left"></i>
              <p class="description">I wouldn't have been able to achieve what I have without the Online Teacher Support
                I received at KIPS Virtual. The teachers were easily approachable and very helpful.</p>
              <div class="success-person-info">
                <img src="/assets/images/successstories/9FatimaSarwar.png" alt="" width="90" height="90" />
                <div class="person-details">
                  <strong class="d-block">Fatima Sarwar</strong>
                  <span class="d-block">2nd(Girls)</span>
                  <span class="d-block">1091 Marks</span>
                  <span class="d-block">HSSC Pre-Medical</span>
                </div>
              </div>
            </div>
          </div>
        </SsrCarousel>
        <div class="btn-container mt-0 mt-lg-4">
          <button @click="openBookDemoPopup()" class="btn secondary-btn mt-4 mt-lg-3">Take a Virtual Tour</button>
          <!-- <button class="btn-advice green-btn text-white mt-4 ml-2 mt-lg-3">
            Free Trail
          </button> -->
        </div>
        <div class="circle-pattern-2">
          <img src="/assets/images/banner/circle-pattern-2.svg " alt="" />
        </div>
      </div>
    </section>
    <section class="videos-section block">
      <div class="container">
        <div class="video-description ">
          <h2 class="heading-2">Video Updates</h2>
          <p>Stay informed about numerous ways in which KIPS helps you achieve academic excellence</p>
        </div>

        <SsrCarousel loop :slides-per-page="3" :responsive="[
          { maxWidth: 1024, slidesPerPage: 2 },
          { maxWidth: 767, slidesPerPage: 1 },
        ]" show-dots>
          <div class="slide">
            <div class="videoBox">
              <a href="https://www.youtube.com/watch?v=lM73ahmEnA8" target="_blank">
                <img src="/assets/images/Unlock-MDCAT.jpg" alt="" width="348" height="206" />
                <div class="video-caption">
                  <p>Unlock MDCAT Excellence with KIPS Virtual's Dynamic Features</p>
                  <div class="play-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Group_9053" data-name="Group 9053" width="60.901"
                      height="60.901" viewBox="0 0 60.901 60.901">
                      <path id="Path_1699" data-name="Path 1699"
                        d="M30.45,0A30.45,30.45,0,1,1,0,30.45,30.45,30.45,0,0,1,30.45,0Z" transform="translate(0 0)"
                        fill="#fff" />
                      <path id="Polygon_7" data-name="Polygon 7"
                        d="M6.413,3.147a2,2,0,0,1,3.5,0l4.762,8.572a2,2,0,0,1-1.748,2.971H3.4a2,2,0,0,1-1.748-2.971Z"
                        transform="translate(39.587 22.645) rotate(90)" fill="#6163b0" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="slide">
            <div class="videoBox">
              <a href="https://www.youtube.com/watch?v=LhxrI-CynOc" target="_blank">
                <img src="/assets/images/KIPS-Education.jpg" alt="" width="348" height="206" />
                <div class="video-caption">
                  <p>KIPS Education System</p>
                  <div class="play-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Group_9053" data-name="Group 9053" width="60.901"
                      height="60.901" viewBox="0 0 60.901 60.901">
                      <path id="Path_1699" data-name="Path 1699"
                        d="M30.45,0A30.45,30.45,0,1,1,0,30.45,30.45,30.45,0,0,1,30.45,0Z" transform="translate(0 0)"
                        fill="#fff" />
                      <path id="Polygon_7" data-name="Polygon 7"
                        d="M6.413,3.147a2,2,0,0,1,3.5,0l4.762,8.572a2,2,0,0,1-1.748,2.971H3.4a2,2,0,0,1-1.748-2.971Z"
                        transform="translate(39.587 22.645) rotate(90)" fill="#6163b0" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="slide">
            <div class="videoBox">
              <a href="https://www.youtube.com/watch?v=ZtNQRY-YDyo" target="_blank">
                <img src="/assets/images/Student-Insights.jpg" alt="" width="348" height="206" />
                <div class="video-caption">
                  <p>Student Insights on KIPS VIRTUAL</p>
                  <div class="play-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Group_9053" data-name="Group 9053" width="60.901"
                      height="60.901" viewBox="0 0 60.901 60.901">
                      <path id="Path_1699" data-name="Path 1699"
                        d="M30.45,0A30.45,30.45,0,1,1,0,30.45,30.45,30.45,0,0,1,30.45,0Z" transform="translate(0 0)"
                        fill="#fff" />
                      <path id="Polygon_7" data-name="Polygon 7"
                        d="M6.413,3.147a2,2,0,0,1,3.5,0l4.762,8.572a2,2,0,0,1-1.748,2.971H3.4a2,2,0,0,1-1.748-2.971Z"
                        transform="translate(39.587 22.645) rotate(90)" fill="#6163b0" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </SsrCarousel>
        <div class="video-btn-wrap" id="lastFeature" ref="lastFeature">
          <a class="btn secondary-btn" href="https://www.youtube.com/@KIPSVirtual" target="_blank">Explore More</a>
        </div>
      </div>
    </section>
    <section class="byTheNumbers" id="byTheNumbers">
      <div class="container">
        <img class="by-numbers-bg d-none d-lg-block" src="/assets/images/Group 12918.png" />
        <div class="row">
          <div class="col-12 by-numbers-heading">
            <h2>By the Numbers</h2>
            <p>By the Numbers A statistical proof of the fact that no institute matches KIPS and its online learning
              platform</p>
          </div>
          <!-- <div class="col-md-4 col-sm-6 numbers">
            <span class="value" id="432500888888888888888888888888"></span>
            <span>No. of Students</span>
          </div> -->
          <div class="col-6 col-md-4 num num-1">
            <img src="/assets/images/readings.png" height="70" width="70" />
            <div class="numbers d-block"><span class="value" id="25359"></span></div>
            <small class="d-block numerLabel">Readings</small>
          </div>
          <div class="col-6 col-md-4 num">
            <img src="/assets/images/videos.png" height="70" width="70" />
            <div class="numbers d-block"><span class="value" id="74469"></span></div>
            <small class="d-block numerLabel">Videos</small>
          </div>
          <div class="col-6 col-md-4 num num-3">
            <img src="/assets/images/mcqs.png" height="70" width="70" />
            <div class="numbers d-block"><span class="value" id="827643"></span></div>
            <small class="d-block numerLabel">MCQs</small>
          </div>
          <div class="col-6 col-md-3 num">
            <img src="/assets/images/user-content.png" height="70" width="70" />
            <div class="numbers d-block"><span class="value" id="484467"></span></div>
            <small class="d-block numerLabel">User Count</small>
          </div>
          <div class="col-6 col-md-3 num">
            <img src="/assets/images/top-positions.png" height="70" width="70" />
            <div class="numbers d-block"> <span class="value" id="500"></span> </div>
            <small class="d-block numerLabel">Top Positions</small>
          </div>
          <div class="col-6 col-md-3 num">
            <img src="/assets/images/community.png" height="70" width="70" />
            <div class="numbers d-block"> <span class="value" id="200000"></span> </div>
            <small class="d-block numerLabel">Community of Alumni </small>
          </div>
          <div class="col-6 col-md-3 num">
            <img src="/assets/images/countries.png" height="70" width="70" />
            <div class="numbers d-block"> <span class="value" id="16"></span> </div>
            <small class="d-block numerLabel">No. of Countries</small>
          </div>
        </div>
      </div>
    </section>

    <section class="flags">

      <vue-marquee-slider id="marquee-slider-loop" :speed="35000" :space="90" :width="60">

        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/pakistan.jpg" height="40" width="60" alt="" /></div>
            <span>Pakistan</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/china.jpg" height="40" width="60" alt="" /></div>
            <span>China</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/italy.jpg" height="40" width="60" alt="" /></div>
            <span>Italy</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/usa.jpg" height="40" width="60" alt="" /></div>
            <span>USA</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/germany.jpg" height="40" width="60" alt="" /></div>
            <span>Germany</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/uae.jpg" height="40" width="60" alt="" /></div>
            <span>UAE</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/canada.jpg" height="40" width="60" alt="" /></div>
            <span>Canada</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/brazil.jpg" height="40" width="60" alt="" /></div>
            <span>Brazil</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/france.jpg" height="40" width="60" alt="" /></div>
            <span>France</span>
          </div>
        </div>
        <div class="slide">
          <div class="country">
            <div><img src="/assets/images/turkey.jpg" height="40" width="60" alt="" /></div>
            <span>Turkey</span>
          </div>
        </div>
      </vue-marquee-slider>
    </section>

    <!-- <section class="blogs-section">
      <div class="container">
        <div class="row">
          <div class="popular-exams-head text-center col-md-12">
            <h2>Blogs</h2>
            <p>Get enlightened with all the important information you need about your education and professional future</p>
          </div>
        </div>
        <SsrCarousel loop :slides-per-page='3' :responsive='[{maxWidth: 1024,slidesPerPage: 2},{maxWidth: 600,slidesPerPage: 1},]' show-dots >
          <div class="slide">
            <div class="blogs-box">
              <a href="https://kips.edu.pk/entry-test-preparation-for-engineering/" target="_blank">
                <div class="img-container">
                  <img src="https://kips.edu.pk/wp-content/uploads/2021/10/Entry-Test-Preparation-for-Engineering-2.jpg" alt="popular-exams" width="350" height="163" />
                </div>
                <div class="container">
                  <div class="blog-container">
                    <div class="blogs-card-title">
                      <h4 class="blogs-card-head">
                        <small class="d-block">May 10, 2023</small>
                        <span class="blog-title-head">Entry Test Preparation for Engineering</span>
                      </h4>
                    </div>
                    <div class="blogs-card-details-container">
                      <div class="row">
                        <div class="col-12"><span class="blogs-card-desc">Students who choose mathematics at the intermediate level and dream to become future engineers need to be aware of their options after passing their intermediate exams. Following is a list of some of the top universities of Pakistan which offer admission to degrees in engineering:</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="slide">
            <div class="blogs-box">
              <a href="https://kips.edu.pk/entry-test-preparation-for-medical-colleges/" target="_blank">
                <div class="img-container">
                  <img src="https://kips.edu.pk/wp-content/uploads/2022/02/Entry-Test-Preparation-for-Medical-Colleges-2.jpg" alt="popular-exams" width="350" height="163" />
                </div>
                <div class="container">
                  <div class="blog-container">
                    <div class="blogs-card-title">
                      <h4 class="blogs-card-head">
                        <small class="d-block">May 16, 2023</small>
                        <span class="blog-title-head">Entry Test Preparation for Medical Colleges</span> 
                      </h4>
                    </div>
                    <div class="blogs-card-details-container">
                      <div class="row">
                        <div class="col-12"><span class="blogs-card-desc">“Before anything else, preparation is the key to success” (Alexander Graham Bell) Entry Test Preparation plays a pivotal role in helping you choose your dream career in your life. If you aim to become a doctor and serve humanity, this article is meant for you. It will guide you about all the fundamental things you need to know about the preparation of entry tests for medical colleges and enlighten you on how you can turn your dream into reality.</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="slide">
            <div class="blogs-box">
              <a href="https://kips.edu.pk/diving-deep-into-entry-test-preparation-learn-and-ace/" target="_blank">
                <div class="img-container">
                  <img src="https://kips.edu.pk/wp-content/uploads/2021/10/Diving-deep-into-Entry-Test-Preparation-–-Learn-and-Ace-2-1.jpg" alt="popular-exams" width="350" height="163" />
                </div>
                <div class="container">
                  <div class="blog-container">
                    <div class="blogs-card-title">
                      <h4 class="blogs-card-head">
                        <small class="d-block">June 05, 2023</small>
                        <span class="blog-title-head">Diving Deep into Entry Test Preparation – Learn and Ace</span>
                      </h4>
                    </div>
                    <div class="blogs-card-details-container">
                      <div class="row">
                        <div class="col-12"><span class="blogs-card-desc">Securing admission into one of the best universities of the country is the gateway to a student’s dream career. Therefore, entry test preparation is one of the most important chapters among the academic activities of a student. Those who aspire to excel seek admission into a preparatory institute and work hard day and night for they know that a fruitful tomorrow demands today’s toil and persistence. The key to crack the code of acing medical or engineering entrance exam is understanding it thoroughly. Before appearing for the exam, students encounter the following questions:</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </SsrCarousel>
        <div class="btn-container">
          <a class="btn secondary-btn mt-lg-3" target="_blank" href="https://kips.edu.pk/blog/">Explore More</a>
        </div>
        <div class="circle-pattern">
          <img src="/assets/images/banner/circle-pattern.svg " alt="" />
        </div>
      </div>
    </section> -->
    <section class="ask-expert-section">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <h1 class="text-white">Need advice about your studies or career?</h1>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <div class="ask-expert-button">
              <button @click="openExpertAdvicePopup()" class="btn-advice yellow-btn mx-auto text-white">Ask an
                Expert</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="kips-lms">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="lms-dec">
              <img class="d-block kips-img" src="/assets/images/banner/kips-logo.svg" alt="" />
              <h2>KIPS VIRTUAL</h2>
              <p>With the KIPS VIRTUAL App, you have your future in your hands, or at least the preparation for it</p>
              <div class="lms-app">
                <a href="https://play.google.com/store/apps/details?id=com.kips.kipslms" target="_blank">
                  <img src="/assets/images/banner/play-store.png" alt="" />
                </a>
                <a href="https://apps.apple.com/pk/app/kips-lms/id1471602811" target="_blank">
                  <img src="/assets/images/banner/apple-store.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="lms-img-cntr">
              <img src="/assets/images/banner/lms-mockup.svg" alt="" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="lms-avatar-cntr">
              <img src="/assets/images/banner/footer-illustration.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
    <b-modal ref="modal-home" id="banner-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9"
        src="https://www.youtube.com/embed/5TxgnAQXISs?rel=0&amp;showinfo=0&amp;autoplay=1" allowfullscreen></b-embed>
    </b-modal>

    <b-modal ref="modal-home" id="testimonialFirst-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9"
        src="https://www.youtube.com/embed/1NtMrenaP7M?rel=0&amp;showinfo=0&autoplay=1" allowfullscreen></b-embed>
    </b-modal>

    <b-modal ref="modal-home" id="testimonialSecond-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9"
        src="https://www.youtube.com/embed/Sy3TKZL4CNk?rel=0&amp;showinfo=0&autoplay=1" allowfullscreen></b-embed>
    </b-modal>

    <b-modal ref="modal-home" id="testimonialThird-video-modal" size="lg" hide-footer hide-header centered>
      <span @click="hideModal" class="modal-close-btn">
        <i class="fa fa-times"></i>
      </span>
      <b-embed type="iframe" aspect="16by9"
        src="https://www.youtube.com/embed/HdsJt_zOS7s?rel=0&amp;showinfo=0&autoplay=1" allowfullscreen></b-embed>
    </b-modal>
    <b-modal scrollable centered text id="bannerPopup" title="" size="xl" hide-footer hide-header-close>
      <span variant="link" class=" close-btn" @click="$bvModal.hide('bannerPopup')">
        &times;
      </span>
      <div v-if="programCategories[0] && programCategories[0].PopupBanner.RedirectPath"
        @click="navigateToPath(programCategories[0].PopupBanner.RedirectPath)" style="cursor: pointer;">
        <img v-if="programCategories[0].PopupBanner.Image" :src="programCategories[0].PopupBanner.Image"
          style="min-height: 300px;" alt="">
      </div>
      <div v-else>
        <img v-if="programCategories[0] && programCategories[0].PopupBanner && programCategories[0].PopupBanner.Image"
          :src="programCategories[0].PopupBanner.Image" style="min-height: 300px;" alt="">
      </div>
    </b-modal>
  </div>
</template>
<script>
import SsrCarousel from "vue-ssr-carousel";
import ssrCarouselCss from "vue-ssr-carousel/index.css";
import classMenu from "../views/course/class/menu";
import examMenu from "../views/course/exam/menu";
import popularExam from "./popularExams";
import Footer from "../layout/landing/appFooter.vue";
import categories from "../components/categories.vue";
import { appConstants } from "../common/constants";
import { mapGetters, mapActions } from "vuex";
import { exam as examStoreKeys, classes as classesStoreKeys, course as courseStoreKeys } from "../store/keys";
import programCategories from "../components/programCategories.vue";
import { programCategories as programCategoriesStoreKeys } from "../store/keys";
export default {
  metaInfo() {
    return {
      title: "KIPS VIRTUAL: Virtual Learning That Transforms Actuality",
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  components: {
    classMenu: classMenu,
    examMenu: examMenu,
    popularExam,
    appFooter: Footer,
    SsrCarousel,
    // categories,
    programCategories,
  },
  data() {
    return {
      counterCheck: true,
      accordianActiveId: "accordion-1",
      settings: {
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2000,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              dots: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              dots: true,
            },
          },
        ],
      },
      model: [
        {
          id: 1,
          url: "/assets/images/banner/e-lectures.svg",
        },
        {
          id: 2,
          url: "/assets/images/banner/Readings.svg",
        },
        {
          id: 3,
          url: "/assets/images/banner/quizzes-n-assignments.svg",
        },

        {
          id: 4,
          url: "/assets/images/banner/live-classes.svg",
        },
        {
          id: 5,
          url: "/assets/images/banner/teacher-support.svg",
        },
        {
          id: 6,
          url: "/assets/images/banner/notes-n-bookmarks.svg",
        },
        {
          id: 7,
          url: "/assets/images/banner/Evaluations.svg",
        },
        {
          id: 8,
          url: "/assets/images/banner/Leaderboard.svg",
        },
        {
          id: 9,
          url: "/assets/images/banner/Ai Quiz.svg",
        },
      ],
      url: "/assets/images/banner/e-lectures.svg",
    };
  },
  asyncData({ store, route }) {
    return Promise.resolve();
  },
  created() {

  },

  mounted() {
    const script = document.createElement("script");
    script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js");
    document.head.appendChild(script);
    window.scrollTo(0, 0);
    if (this.$route.hash) {
      var elm = this.$route.hash.slice(1);
      this.scrollToElement(elm);
    }
    window.addEventListener("scroll", () => {
      if (this.$refs.lastFeature) {
        if (this.counterCheck && this.isScrolledIntoView("lastFeature")) {
          const obj = document.querySelectorAll(".value");
          let selectedNodes = [...obj];
          this.animateValue(selectedNodes, 0, 2500);
          this.counterCheck = false;
        }
      }
    });
  },
  computed: {
    ...mapGetters(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.getters),
    ...mapGetters(classesStoreKeys.namespace, classesStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
  },
  methods: {
    ...mapActions(classesStoreKeys.namespace, classesStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.actions),

    getCleanPath(url) {
      try {
        const parsedUrl = new URL(url);
        const path = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
        return path || '/';
      } catch (e) {
        return url;
      }
    },
    navigateToPath(path) {
      const cleanedPath = this.getCleanPath(path);
      this.$router.push({ path: cleanedPath });
    },
    updateSelectedClass(item) {
      this.reset_SelectedCourseInfo({});
      this.update_SelectedClass({
        data: {
          program: { id: item.ProgramId, name: item.Name },
          programCategory: {
            id: appConstants.enums.programCategory.Class,
            name: "",
          },
        },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          this.RouteTo("/class/" + this.RemoveSpecialChars(item.Name) + "-" + item.ProgramId);
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    redirectToExam(item) {
      this.RouteTo("/exam/" + this.RemoveSpecialChars(item.CourseGroupCategoryName));
    },
    redirectToProgramDetail(event) {
      let item = event.target.textContent;
      this.RouteTo("/programDetail/" + this.RemoveSpecialChars(item));
    },
    hideModal() {
      this.$refs["modal-home"].hide();
    },
    getFileAbsoluteCdnPath(fileRelativePath) {
      return process.env.VUE_APP_CDN_BASE_URL + fileRelativePath;
    },
    findId(id) {
      let result = this.model.find((c) => c.id === id);
      this.url = result.url;
    },
    animateValue(obj, start, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.map((val) => (val.innerHTML = Math.floor(progress * (val.id - start) + start).toLocaleString() + "+"));
        // obj.innerHTML = Math.floor(progress * (end - start) + start).toLocaleString();
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    isScrolledIntoView(elem) {
      let scrollY = Math.round(window.scrollY);
      var divTop = document.getElementById(elem).offsetTop;
      var divBottom = divTop + document.getElementById(elem).offsetHeight;
      return divTop <= scrollY && divBottom >= scrollY;
    },
    openBookDemoPopup() {
      Calendly.initPopupWidget({
        url: "https://calendly.com/kipsvirtual/book-a-demo",
      });
      return false;
    },
    openExpertAdvicePopup() {
      Calendly.initPopupWidget({
        url: "https://calendly.com/kipsvirtual/ask-an-expert",
      });
      return false;
    },
  },
};
</script>
<style scoped>
.stop-click {
  pointer-events: none;
}

.not-collapsed {
  pointer-events: none;
}

.accordion .collapse.show {
  pointer-events: none;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #ffffff;
  opacity: 0.7;
  cursor: pointer;
}

.close-btn:hover {
  opacity: 1;
}
</style>
